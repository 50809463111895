<template>
	<span>
		<LoadingImg v-if="loading.list" />
		<div class="ol-dashboard__address">
			<h4 class="ol-dashboard__container-title">Alamat</h4>
			<a v-on:click="showPopup(true)" class="ol-btn ol-dashboard__address-add">+ Tambah Alamat</a>
			<div class="ol-checkout-content__shipping ol-dashboard__address-shipping ol-dashboard__address-bank" style="margin-top:30px">
				<div class="ol-checkout-content__shipping-list">
					<Alert v-show="alertList.message !=null " :type="alertList.type" :message="alertList.message"/>
                    <table class="ol-table-responsive">
                        <thead>
                            <tr>
                                <th>Penerima</th>
                                <th>Alamat</th>
                                <th>Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in addresses" :key="item.id">
                                <td>
									<b>{{item.name}}</b><br>
									<span>{{item.phone}}</span>
								</td>
                                <td>
									{{item.address}}, {{item.postal_code}}, {{item.village_name}}, {{item.district_name}}, {{item.city_name}}, {{item.province_name}}
								</td>
                                <td align="center">
									<a title="Edit" v-on:click="edit(item.id)"><i class="far fa-edit"></i></a>
									<a class="ol-del" v-on:click="del(item.id)"><i class="fas fa-trash"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
					
				</div>
                <a href="/checkout" class="ol-btn ol-btn--secondary">Checkout</a>
			</div>
			<!-- <div class="ol-checkout-content__shipping ol-dashboard__address-shipping ol-dashboard__address-bank">
				<div class="ol-checkout-content__shipping-list">
					<Alert v-show="alertList.message !=null " :type="alertList.type" :message="alertList.message"/>
					<div class="ol-checkout-content__shipping-list-head">
						<div class="ol-checkout-content__shipping-list-head-item">
							
						</div>
						<div class="ol-checkout-content__shipping-list-head-item">
							
						</div>
						<div class="ol-checkout-content__shipping-list-head-item">
							Alamat
						</div>
						<div class="ol-checkout-content__shipping-list-head-item">
							Aksi
						</div>
					</div>
					<div class="ol-checkout-content__shipping-list-body">
						<form id="pilih_alamat">
							<transition-group name="fade">
								<label v-for="item in addresses" :key="item.id" :class="'ol-checkout-content__shipping-list-body-wrapper '+ (item.default == 1 ? 'checked':'')">
									
									<div class="ol-checkout-content__shipping-list-body-item" style="display:block">
										<label v-if="item.default == 1"  class="ol-checkout-content__shipping-list-body-item-label">
											<input class="ol-input--radio-check" name="default-address" type="radio" checked>
											<i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio ol-input--radio-checkmark--dd"></i>
										</label>
									</div>
									<div class="ol-checkout-content__shipping-list-body-item" style="width:25%">
										
									</div>
									<div class="ol-checkout-content__shipping-list-body-item">
										
									</div>
									<div class="ol-checkout-content__shipping-list-body-item">
									</div>
								</label>
							</transition-group>
						</form>
						<label v-show="addresses.length < 1" class="ol-checkout-content__shipping-list-body-wrapper">
							Belum ada Alamat yang disimpan
						</label>
					</div>
				</div>
			</div> -->
		</div>
		<Popup :title="title">
			<LoadingImg v-if="loading.save" />
			<div>
                <Alert v-show="validateBackend !=null " type="danger" :collection="validateBackend"/>

				<div class="ol-input-box ol-input-box--full">
					<label class="ol-input-label">Nama Lengkap</label>
					<input type="text" class="ol-input" v-model="input.name">
				</div>
				<div class="ol-input-box ol-input-box--half">
					<label class="ol-input-label">Telepon</label>
					<input type="number" class="ol-input" v-model="input.phone">
				</div>
				<div class="ol-input-box ol-input-box--half">
					<label class="ol-input-label">Provinsi</label>
						<select id="provinces" class="ol-input ol-input-select select2" >
							<!-- <option value=""></option> -->
							<!-- <option v-for="province in provinces" :key="province.province_id" :value="province.province_id">{{province.province_name}}</option> -->
						</select>
				</div>
				<div class="ol-input-box ol-input-box--half">
					<label class="ol-input-label">Kota/Kabupaten</label>
					<select id="cities" class="ol-input ol-input-select select2"> </select>
				</div>
				<div class="ol-input-box ol-input-box--half">
					<label class="ol-input-label">Kecamatan</label>
					<select id="districts" class="ol-input ol-input-select select2"> </select>
				</div>
				<div class="ol-input-box ol-input-box--half">
					<label class="ol-input-label ">Kelurahan</label>
					<select id="villages" class="ol-input ol-input-select select2"> </select>
				</div>
				<div class="ol-input-box ol-input-box--half">
					<label class="ol-input-label">Kode Pos</label>
					<input type="text" class="ol-input" v-model="input.postal_code">
				</div>
				<div class="ol-input-box ol-input-box--full">
					<label class="ol-input-label">
						Alamat
					</label>
					<textarea class="ol-input" v-model="input.address"></textarea>
					<label class="ol-product-content__sidebar-item-list-item-link">
						<input class="ol-input--radio-check" v-model="input.default" true-value=1 false-value=0 type="checkbox">
							Simpan sebagai alamat utama 
						<i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio"></i>
					</label>
				</div>

				<div class="ol-input-box ol-input-box--half">
					<button type="button" name="" v-on:click="save()" class="ol-btn">Simpan</button>
				</div>
			</div>
		</Popup>
	</span>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'
import LoadingImg from '../../components/loading'
import Popup from '@/components/popup/Popup.vue'
import select2 from '@/lib/select2'
import globalUrl from '@/util/globallUrl'
const UNI = globalUrl.WS_UNI;
const BASE = process.env.VUE_APP_BASE_API_URL+"/";

export default {
    name:"DashboardAddress",
    components:{
		LoadingImg,
		Popup,
    },
	data(){
        return{
            title: null,
            input:{
				id:null,
                name: null,
				address: null,
				province: null,
				city: null,
				district: null,
				village: null,
				postal_code: null,
				phone: null,
				default: 0,
            },
			loading:{
                list:true,
                save:false,
            },
			validateBackend:null,
            alertList:{
				message:null,
				type:"info",
			},
        }
    },
	computed:{
        ...mapGetters('profile/address', ['provinces','addresses']),
    },
    methods:{
        ...mapActions('profile/address', ['fetchProvinces','fetchAddresses','saveAddress','updateAddress','delAddress']),
        ...mapActions('auth', ['fetchProgressProfile']),

        showPopup(show=true,isNew=true){  
            this.title="Edit Alamat";
            if(isNew){
                this.title="Tambah Alamat";
				this.input.id= null;
				this.input.name= null;
				this.input.address= null;
				this.input.province= null;
				this.input.city= null;
				this.input.district= null;
				this.input.village= null;
				this.input.postal_code= null;
				this.input.phone= null;
				this.input.default= 0;
				$("select#provinces,select#cities,select#districts,select#villages").val(null).trigger("change");				
            }
            (show)? Popup.methods.show():Popup.methods.hide();
			this.validateBackend = null;
            this.loading.save = false;
        },
        
        async save(){
			this.alertList.message=null;
            this.validateBackend=null;
			this.loading.save = true;
			const result = (!this.input.id) ? await this.saveAddress(this.input) : await this.updateAddress(this.input);
			if(result.success){
				this.showPopup(false)  
				this.alertList.message = "Berhasil menyimpan alamat";
				this.alertList.type="success";
				this.fetchProgressProfile();
			}else{
				this.validateBackend=result.data.message ? {address:[" Gagal menyimpan alamat, coba beberapa saat lagi"]}:result.data;
				this.loading.save = false;
				// this.showPopup(false)  
			}
        },
        edit(id){
            const address = _.find(this.addresses,{"id":id});
        	this.input.id = address.id;
        	this.input.name = address.name;
			this.input.address = address.address;
			this.input.province = address.province;
			this.input.city = address.city;
			this.input.district = address.district;
			this.input.village = address.village;
			this.input.postal_code = address.postal_code;
			this.input.phone = address.phone;
			this.input.default = Number(address.default);
            // $("select#provinces").val(this.input.province).trigger("change");
    		$("select#provinces").append(new Option(address.province_name, address.province, true, true)).trigger('change');
    		$("select#cities").append(new Option(address.city_name, address.city, true, true)).trigger('change');
    		$("select#districts").append(new Option(address.district_name, address.district, true, true)).trigger('change');
    		$("select#villages").append(new Option(address.village_name, address.village, true, true)).trigger('change');
            this.showPopup(true,false);
        },
        del(id){
			this.alertList.message=null;
            Swal.fire({
                text: "Yakin ingin dihapus?",
                icon: 'warning',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '#364b7b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Hapus',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.loading.list = true;
                    const result = await this.delAddress(id);
                    if(result.success){
						this.message =  "Berhasil menghapus alamat";
						this.alertList.message = "Berhasil menghapus alamat";
						this.alertList.type="success";
                        this.loading.list = false;  
                    }else{
						this.alertList.message = result.data.message? result.data.message:"Gagal menghapus alamat, coba beberapa saat lagi";
						this.alertList.type="danger";
                        this.loading.list = false;  
                    }

                }
            })
        },
		jq(){
			let input = this.input;
			let villages = null;

			select2.ajax("select#provinces",BASE+UNI._PROVINCES,'province_id','province_name');

			$("select#provinces").change(function(){
				input.province = $(this).val();
				if(input.province){
					$("select#cities,select#districts,select#villages").val(null).trigger("change");				
					select2.ajax("select#cities",BASE+UNI._CITIES+"?province_id="+input.province,'city_id','city_name')
				}
			})

			$("select#cities").change(function(){
				input.city = $(this).val();
				if(input.city){
					$("select#districts,select#villages").val(null).trigger("change");		
					select2.ajax("select#districts",BASE+UNI._DISTRICTS+"?city_id="+input.city,'district_id','district_name')
				}
			})

			$("select#districts").change(function(){
				input.district =  $(this).val();
				if(input.district){
					$("select#villages").val(null).trigger("change");	
					select2.ajax("select#villages",BASE+UNI._VILLAGES+"?district_id="+input.district,'village_id','village_name',function(result){
						villages = result;
					})
				}
			});

			$("select#villages").change(function(){
				input.village = $(this).val();
				if(input.village) {
					let village = _.find(villages,{village_id:input.village});
					if(village){
						input.postal_code = village.village_postcode > 0 ? village.village_postcode:null;
					}
				}
			})
		}
    },
    async mounted(){
		this.jq();
        await this.fetchAddresses() ? this.loading.list=false : this.$toast.open({ message: "Gagal mendapatkan data alamat", type: "error", duration: 3000, });
        // await this.fetchProvinces() ? this.loading.list=false : this.$toast.open({ message: "Gagal mendapatkan data provinsi", type: "error", duration: 3000, });
    }

}
</script>

<style>
table {
  border-collapse: collapse;
  width:100%;
}

table, th, td {
  border: 1px solid #D2D2D2;
  padding:4px;

}
</style>